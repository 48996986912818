import { Box, Typography } from "@mui/material"

export const MarketingCard = ({ activeStep, page }) => {
    const getContent = (step, page) => {
        if (page === 1) {
            if (step === 0) {
                return (<Typography variant="h5">Our typical customers use Lendica to delay vendor bills and speed up collection from their customers.</Typography>)
            } else if (step === 1) {
                return (<Typography variant="h5">Your Lendica line grows over time which means even if you start off small, big things are coming.</Typography>)
            } else if (step === 2) {
                return (<Typography variant="h5">Applying for Lendica will have no impact on your personal or business credit score.</Typography>)
            } else if (step === 3) {
                return (<Typography variant="h5">Your business transactions are crucial for underwriting.</Typography>)
            } else if (step === 4) {
                return (<Typography variant="h5">Create an account to view your funding at any time!</Typography>)
            }
        } else if (page === 2) {
            return (<Typography variant="h5">Providing a complete profile on your business can increase your funding amount by more than 30%.</Typography>)
        } else if (page === 3) {
            return (<Typography variant="h5">Congrats, your application has been submitted!</Typography>)
        }

    }

    return (
        <Box
            sx={{
                height: 'fit-content',
                width: '100%',
                padding: '1.5rem 1rem',
                background: '#f2f2f8',
                borderRadius: 2,
                boxSizing: 'border-box',
                margin: '1rem 0'
            }}
        >
            {getContent(activeStep, page)}
        </Box>
    )
}