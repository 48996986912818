import { useState, useEffect } from 'react';
import { Logo, RoundedLoadingButton } from '@lendica/components';
import { Box } from '@mui/system';
import { OfferTerms } from '../components/OfferTerms';
import { MarketingCard } from '../components/MarketingCard';
import { BreadCrumbs } from '../components/ShippingBreadCrumbs';
import { useNavigate, useParams } from 'react-router-dom';
import { RoundedButton } from '@lendica/components';
import { ShippingInfoCard } from '../components/ShippingInfoCard';
import { ShippingOptionCard } from '../components/ShippingOptionCard';
import * as api from '../api/index';
import { CircularProgress } from '@mui/material';
import { LoadingButton } from '@mui/lab';

export const ShippingOptionsPage = () => {
    const { bill_id } = useParams();

    const navigate = useNavigate();

    const [loading, setLoading] = useState(false);
    const [orderDetails, setOrderDetails] = useState({});
    const [selectedOption, setSelectedOption] = useState('');

    const handleSelectedShippingOption = value => {
        setSelectedOption(value);
    };

    useEffect(() => {
        const getOrderDetails = async () => {
            const res = await api.getShippingMethods(bill_id);
            setOrderDetails(res);
            setSelectedOption(res.shipping_options[0].handle);
        };
        getOrderDetails();
    }, []);

    const handleSubmit = () => {
        setLoading(true);
        setTimeout(async () => {
            await api.postShippingMethod(
                bill_id,
                orderDetails.shipping_options.find(x => x.handle === selectedOption)
            );
            navigate('../application');
        }, 1500);
    };

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                minWidth: 350,
                width: '100%',
                maxWidth: 600,
                paddingX: 2,
                boxSizing: 'border-box',
            }}
        >
            <Logo height={20} />
            <BreadCrumbs page={2} />
            {Object.values(orderDetails).length !== 0 ? (
                <>
                    <ShippingInfoCard shippingDetails={orderDetails.shipping_details} />
                    <ShippingOptionCard
                        rates={orderDetails.shipping_options}
                        setSelectedOption={handleSelectedShippingOption}
                        selectedOption={selectedOption}
                    />
                </>
            ) : (
                <Box
                    sx={{
                        width: '100%',
                        height: 500,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <CircularProgress />
                </Box>
            )}

            <Box sx={{ display: 'flex', flexDirection: 'row', mt: 2 }}>
                <RoundedButton
                    sx={{
                        flex: 1,
                        mt: 1,
                        mr: 1,
                        borderColor: 'primary.light',
                        border: '1px solid',
                        color: 'primary.main',
                    }}
                    onClick={() => navigate(`../shipping-info/${bill_id}`)}
                >
                    Back
                </RoundedButton>
                <RoundedLoadingButton
                    sx={{ flex: 1, mt: 1 }}
                    variant="contained"
                    color="primary"
                    type="submit"
                    // onClick={() => navigate('../application')}
                    onClick={handleSubmit}
                    loading={loading}
                >
                    Continue
                </RoundedLoadingButton>
            </Box>
        </Box>
    );
};
