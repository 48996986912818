import { Box, Chip, Typography } from "@mui/material"
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(theme => ({
    'root': {
        height: '275px',
        width: 275,
        border: '1px solid #d0d7de',
        borderRadius: 6,
        padding: '1rem',

        [theme.breakpoints.down('md')]: {
            display: 'none'
        }
    }
}))

export const OfferTerms = () => {
    const classes = useStyles();

    return (
        <Box className={classes.root}>
            <Typography>Offer Terms</Typography>

            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: 5 }}>
                <Typography variant="h5">$1,733.63</Typography>
                <Chip label="2 Weeks" color="secondary" />
            </Box>

            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: 5 }}>
                <Typography>Rate(%)</Typography>
                <Typography>1.35%</Typography>
            </Box>

            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography>Rate($)</Typography>
                <Typography>$47.25</Typography>
            </Box>

            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography>Weeks</Typography>
                <Typography>2</Typography>
            </Box>

            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: 5 }}>
                <Typography variant="h5">Total</Typography>
                <Typography>$5,082.75</Typography>
            </Box>
        </Box >
    )
}