import { DealStatus } from "./sharedTypes";

export const billStatusToColor = {
    [DealStatus.Available]: 'text',
    [DealStatus.Submitted]: 'info',
    [DealStatus.InProgress]: 'success',
    [DealStatus.Overdue]: 'error',
    [DealStatus.Complete]: 'info',
    [DealStatus.Invalid]: 'error',
};

export const billStatusToLabel = {
    [DealStatus.Available]: 'Available',
    [DealStatus.Submitted]: 'Submitted',
    [DealStatus.InProgress]: 'In Progress',
    [DealStatus.Overdue]: 'Overdue',
    [DealStatus.Complete]: 'Complete',
    [DealStatus.Invalid]: 'Invalid',
};