import { formatDateDashed } from "@lendica/utils"
import { isValid, parse, compareDesc, format } from "date-fns"

export const isValidDate = (date) => {
    const formattedDate = parse(date, 'MM/dd/yyyy', new Date())
    return isValid(formattedDate)
}

export const isBeforeToday = (date) => {
    const formattedDate = parse(date, 'MM/dd/yyyy', new Date())
    const compared = compareDesc(formattedDate, new Date())
    return compared >= 0
}

export const formatDate = (date, date_format) => {
    let formattedDate

    if (date_format === 'MM/dd/yyyy') {
        formattedDate = format(parse(date, 'MM/dd/yyyy', new Date()), 'yyyy-MM-dd')
    } else if (date_format === 'yyyy-MM-dd') {
        formattedDate = format(parse(date, 'yyyy-MM-dd', new Date()), 'MM/dd/yyyy')
    }
    return formattedDate
}
