import { Box, Typography } from "@mui/material"

export const ShippingInfoCard = ({ shippingDetails }) => {
    const {
        shipping_first_name,
        shipping_last_name,
        shipping_address1,
        shipping_address2,
        shipping_city,
        shipping_phone,
        shipping_province,
        shipping_province_code,
        shipping_zip
    } = shippingDetails

    let name

    if (shipping_first_name === '') {
        name = shipping_last_name
    } else {
        name = shipping_first_name + ' ' + shipping_last_name
    }

    let shipping_address = `${shipping_address1}${shipping_address2 ? ` ${shipping_address2}` : ''} ${shipping_city}, ${shipping_province_code} ${shipping_zip}`

    return (
        <Box
            sx={{
                width: '100%',
                height: 'fit-content',
                border: '1px solid #d0d7de',
                borderRadius: 2,
                padding: '1rem',
                boxSizing: 'border-box',
                mb: 4,
                mt: 2
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    borderBottom: '1px solid #d0d7de',
                    paddingBottom: '0.75rem'
                }}
            >
                <Typography variant="caption" fontSize={14} fontWeight={400} mb={0.5}>Contact</Typography>
                <Typography variant="h4" fontSize={14} fontWeight={500}>{name}</Typography>
            </Box>

            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    paddingTop: '0.75rem'
                }}
            >
                <Typography variant="caption" fontSize={14} fontWeight={400} mb={0.5}>Shipping Address</Typography>
                <Typography variant="h4" fontSize={14} fontWeight={500}>{shipping_address}</Typography>
            </Box>
        </Box >
    )
}