import { createRoot } from 'react-dom/client';
import { ThemeProvider } from '@mui/material';
import { App } from './App';
import { theme } from './theme';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

const root = createRoot(document.getElementById('app'));

root.render(
    <ThemeProvider theme={theme}>
        <BrowserRouter>
            <Routes>
                <Route path="*" element={<App />} />
            </Routes>
        </BrowserRouter>
    </ThemeProvider>
);
