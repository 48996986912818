import { ApplicationForm } from '@lendica/application-form';
import { Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
// import { OfferTerms } from './OfferTerms'
import { env } from './api/axiosInstance';

export const AppContainer = () => {
    const navigate = useNavigate();

    const onComplete = status => {
        navigate(`../submitted`);
    };

    return (
        <Box
            sx={{
                height: 'fit-content',
                minHeight: '100vh',
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
                paddingTop: '1.5rem',
                paddingBottom: '3rem',
                boxSizing: 'border-box',
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    width: '100%',
                    maxWidth: 900,
                    justifyContent: 'center',
                }}
            >
                <ApplicationForm onComplete={onComplete} env={env} />
                {/* <Box
                    sx={{
                        height: '100%',
                        width: 'fit-content',
                        display: 'flex',
                        flexDirection: 'column',
                        marginTop: 7
                    }}
                >
                    <OfferTerms />
                </Box> */}
            </Box>
        </Box>
    );
};
