import { Box, Typography, TextField, InputAdornment, Button } from '@mui/material';
import { useSearchParams } from 'react-router-dom';
import { Logo } from '@lendica/components';
import { useState, useEffect } from 'react';
import { TermCard } from '../components/TermCard';

export const PreviewTermsCalculator = () => {
    const [amount, setAmount] = useState(1000);

    // const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    const handleChange = e => {
        setAmount(e.target.value);
    };

    useEffect(() => {
        if (searchParams.has('amt')) {
            const amt = +searchParams.get('amt');
            if (typeof amt === 'number' && !isNaN(amt)) {
                setAmount(amt.toFixed(2) || 1000);
            }
        }
    }, []);

    return (
        <Box
            sx={{
                height: 'fit-content',
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                // alignItems: 'center',
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    width: 400,
                    height: 'fit-content',
                    textAlign: 'center',
                    paddingY: 2,
                }}
            >
                <Logo height={18} />
                <Typography variant="h4" fontSize={22} my={2}>
                    Boost your buying power and step up to bigger quantity discounts.
                </Typography>
                <Typography variant="h5" fontSize={18}>
                    Enter an amount to see sample payment plans
                </Typography>
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        my: 2,
                    }}
                >
                    <Typography variant="h6" fontSize={16}>
                        Enter invoice amount
                    </Typography>
                    <TextField
                        id="amount"
                        sx={{ m: 1, width: '10rem' }}
                        InputProps={{
                            startAdornment: <InputAdornment position="start">$</InputAdornment>,
                        }}
                        variant="outlined"
                        hiddenLabel
                        size="small"
                        value={amount}
                        onChange={handleChange}
                    />
                </Box>

                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%',
                        height: 'fit-content',
                    }}
                >
                    {/* <TermCard amount={amount} length={2} rate={0.0121} /> */}
                    <TermCard amount={amount} length={4} rate={0.0109} />
                    <TermCard amount={amount} length={6} rate={0.0169} />
                    <TermCard amount={amount} length={8} rate={0.0239} />
                </Box>

                <Button
                    variant="contained"
                    color="secondary"
                    size="large"
                    fullWidth
                    sx={{ my: 2 }}
                    onClick={() => (window.location = 'https://apply.app.golendica.com')}
                >
                    Get approved now
                </Button>

                <Typography variant="caption">
                    Rates and terms will vary based on credit score and other factors.
                </Typography>
            </Box>
        </Box>
    );
};
