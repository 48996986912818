import { currencyFormatter } from "@lendica/utils";
import { Box, Typography } from "@mui/material"
import Radio from '@mui/material/Radio';

export const ShippingOptionCard = ({ rates, setSelectedOption, selectedOption }) => {

    const handleChange = (e) => {
        setSelectedOption(e.target.value)
    }

    const getTransitTime = (title) => {
        let time_description = ''

        if (title === 'Economy') {
            time_description = '5 to 8 business days'
        } else if (title === 'Standard') {
            time_description = '3 to 4 business days'
        } else if (title === 'Express') {
            time_description = '2 business days'
        }
        return time_description
    }

    return (
        <>
            <Typography variant='h6' my={2}>Shipping Method</Typography>
            <Box
                sx={{
                    width: '100%',
                    height: 'fit-content',
                    border: '1px solid #d0d7de',
                    borderRadius: 2,
                    padding: '1rem',
                    boxSizing: 'border-box',
                }}
            >
                {rates.map(rate => (
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            paddingBottom: '0.75rem',
                            justifyContent: 'space-between',
                            alignItems: 'center'
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row'
                            }}
                        >
                            <Radio
                                checked={selectedOption === rate.handle}
                                onChange={handleChange}
                                value={rate.handle}
                                disableRipple
                                color="primary"
                            />
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column'
                                }}
                            >
                                <Typography variant="h6" fontSize={14} fontWeight={400} mb={0.5}>{rate.title}</Typography>
                                <Typography variant="caption" fontSize={14} fontWeight={400} mb={0.5}>{getTransitTime(rate.title)}</Typography>
                            </Box>

                        </Box>

                        <Box
                            sx={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', mr: 2 }}
                        >
                            <Typography variant="h4" fontSize={14} fontWeight={500}>{currencyFormatter(rate.price.amount)}</Typography>
                        </Box>
                    </Box>
                ))}
            </Box >
        </>
    )
}