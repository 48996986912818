import { createContext, useContext, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { getCompanyDetails } from '@lendica/api';
import { LoadingScreen } from '@lendica/components';

const ApplicationContext = createContext({});

export const ApplicationProvider = ({ children }) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [loading, setLoading] = useState(true);
    const [consent, setConsent] = useState(null);
    const [partnerName, setPartnerName] = useState(null);

    useEffect(() => {
        (async () => {
            try {
                const res = await getCompanyDetails();

                if (!res) {
                    return;
                }
                setConsent(res.data_consent);
                setPartnerName(res.partner_name);
            } catch (e) {
                console.log(e);
            } finally {
                setLoading(false);
            }
        })();
    }, []);

    return loading ? (
        <LoadingScreen />
    ) : (
        <ApplicationContext.Provider
            value={{
                loading,
                searchParams,
                consent,
                toggleConsent: () => setConsent(true),
                partnerName,
            }}
        >
            {children}
        </ApplicationContext.Provider>
    );
};

export const useApplication = () => {
    return useContext(ApplicationContext);
};
