import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel'
import { FormControl, FormHelperText } from '@mui/material';
import { states } from '../utils/state';

export const StateSelect = ({ handleChange, value, error, helperText }) => {

    const onChange = (e) => {
        handleChange(e.target.value)
    }

    return (
        <FormControl sx={{ flex: 1 }} size="small" color='primary' error={error}>
            <InputLabel id="state-select-label">State</InputLabel>
            <Select
                labelId="state-select-label"
                id="state-select-error"
                value={value}
                label="State"
                onChange={onChange}
                error={error}
            >
                {states.map(state => (
                    <MenuItem key={state.abbreviation} value={state.name}>{state.name}</MenuItem>
                ))}
            </Select>
            <FormHelperText color={'error'}>{error && helperText}</FormHelperText>
        </FormControl>
    )
}