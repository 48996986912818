import { Box, Typography } from '@mui/material';
import { FC, ReactNode } from 'react';

export interface HeaderToolbarProps {
    label: string;
    leftAction?: ReactNode;
    rightAction?: ReactNode;
}

export const HeaderToolbar: FC<HeaderToolbarProps> = ({ label, leftAction, rightAction }) => {
    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                minHeight: 64,
            }}
        >
            <Box sx={{ flex: 1 }}>{leftAction}</Box>
            <Box>
                <Typography variant="subtitle1" fontWeight="regular">
                    {label}
                </Typography>
            </Box>
            <Box sx={{ flex: 1, display: 'flex', justifyContent: 'flex-end' }}>{rightAction}</Box>
        </Box>
    );
};