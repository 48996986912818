import Breadcrumbs from '@mui/material/Breadcrumbs';
import Stack from '@mui/material/Stack';
import { Typography, Link } from '@mui/material';
import { useNavigate } from 'react-router-dom';

export const BreadCrumbs = ({ page }) => {
    const navigate = useNavigate();

    const breadcrumbs = [
        <Link
            key="1"
            color={page === 1 ? 'text.primary' : 'text.disabled'}
            fontSize={14}
            fontWeight={page === 1 ? 500 : 400}
            underline={page <= 1 ? 'none' : 'hover'}
            onClick={() => navigate(-1)}
            component={'button'}
            disabled={page <= 1}
        >
            Information
        </Link>,
        <Link
            key="2"
            color={page === 2 ? 'text.primary' : 'text.disabled'}
            fontSize={14}
            fontWeight={page === 2 ? 500 : 400}
            underline={page <= 2 ? 'none' : 'hover'}
            onClick={() => navigate(`../shipping-method`)}
            component={'button'}
            disabled={page <= 2}
        >
            Shipping
        </Link>,
    ];

    return (
        <Stack spacing={2} mt={6} mb={1}>
            <Breadcrumbs separator="›" aria-label="breadcrumb">
                {breadcrumbs}
            </Breadcrumbs>
        </Stack>
    );
};
