// old backend api
export const getBackendURL = (env = 'SANDBOX') => {
    if (typeof env === 'string') {
        env = env.toUpperCase();
    }
    if (/PRODUCTION/gi.test(env)) {
        return 'https://prod.golendica.com/api/v1';
    }
    if (/DEV/gi.test(env)) {
        return 'https://idev.golendica.com/api/v1';
    }
    if (/QA/gi.test(env)) {
        return 'https://iqa.golendica.com/api/v1';
    }
    if (/LOCAL/gi.test(env)) {
        if (process.env.NODE_ENV === 'development') {
            // return 'https://prod.golendica.com/api/v1';
            return 'https://idev.golendica.com/api/v1';
        } else {
            // return 'http://127.0.0.1:8000/api/v1';
            return 'https://sandbox.golendica.com/api/v1'
        }
    }
    return 'https://sandbox.golendica.com/api/v1';
};

// old backend api
export const getBackendURLFromHost = (hostname: string) => {
    hostname = hostname.toUpperCase();
    if (/SANDBOX/gi.test(hostname)) {
        return 'https://sandbox.golendica.com/api/v1';
    }
    if (/DEV/gi.test(hostname)) {
        return 'https://idev.golendica.com/api/v1';
    }
    if (/QA/gi.test(hostname)) {
        return 'https://iqa.golendica.com/api/v1';
    }
    if (/LOCAL/gi.test(hostname) || /127\.0\.0\.1/gi.test(hostname)) {
        if (process.env.NODE_ENV === 'development') {
            // return 'https://prod.golendica.com/api/v1';
            return 'https://idev.golendica.com/api/v1';
        } else {
            // return 'http://127.0.0.1:8000/api/v1';
            return 'https://sandbox.golendica.com/api/v1'
        }
    }
    return 'https://prod.golendica.com/api/v1';
};

export const getEnvFromHost = (hostname: string) => {
    hostname = hostname.toUpperCase();
    if (/SANDBOX.GOLENDICA/gi.test(hostname)) {
        return 'sandbox';
    }
    if (/DEV.GOLENDICA/gi.test(hostname)) {
        return 'idev';
    }
    if (/QA.GOLENDICA/gi.test(hostname)) {
        return 'iqa';
    }
    if (/LOCAL/gi.test(hostname) || /127\.0\.0\.1/gi.test(hostname)) {
        return 'test';
    }
    return 'prod';
};

export const matchBackend = () => {
    if (/idev.golendica/gi.test(`${window.location}`)) {
        return 'https://api-dev.golendica.com/api/v1';
    }
    if (/sandbox.golendica/gi.test(`${window.location}`)) {
        return 'https://api-sandbox.golendica.com/api/v1';
    }
    if (/iqa.golendica/gi.test(`${window.location}`)) {
        return 'https://api-qa.golendica.com/api/v1';
    }
    if (/local/gi.test(`${window.location}`) || /127.0.0.1/gi.test(`${window.location}`)) {
        return 'https://api-dev.golendica.com/api/v1';
    }
    return 'https://api.golendica.com/api/v1';
};

export const matchHostname = () => {
    if (/idev.golendica/gi.test(`${window.location}`)) {
        return 'app-idev.golendica.com';
    }
    if (/sandbox.golendica/gi.test(`${window.location}`)) {
        return 'app-sandbox.golendica.com';
    }
    if (/iqa.golendica/gi.test(`${window.location}`)) {
        return 'app-iqa.golendica.com';
    }
    if (/local/gi.test(`${window.location}`) || /127.0.0.1/gi.test(`${window.location}`)) {
        return 'app-idev.golendica.com';
    }
    return 'app.golendica.com';
}
