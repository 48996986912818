import { Navigate } from 'react-router-dom';
import { useApplication } from '../context/useApplication';
import { LoadingScreen } from '@lendica/components';

export const ConsentProtector = ({ children }) => {
    const { loading, consent } = useApplication();

    return loading ? (
        <LoadingScreen />
    ) : consent === false ? (
        <Navigate to="../consent" replace />
    ) : (
        children
    );
};
