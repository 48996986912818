import { currencyFormatter, percentageFormatter } from "@lendica/utils"
import { Chip, Typography, Box } from "@mui/material"

export const TermCard = ({ amount, rate, length }) => {

    let interest = amount * rate
    let totalAmount = parseInt(amount) + interest
    let weeklyAmount = totalAmount / length

    return (
        <Box
            sx={{
                padding: '1rem',
                boxSizing: 'border-box',
                boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
                borderRadius: 2,
                mb: 2
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    mb: 2,
                }}
            >
                <Typography variant="h6" color='primary'>{`${currencyFormatter(weeklyAmount)} /week`}</Typography>
                <Chip label={`${length} Weeks`} color='primary' />
            </Box>

            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between'
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                >
                    <Typography textAlign='left' fontSize={14} fontWeight={450}>Rate</Typography>
                    <Typography textAlign='left' fontSize={14} fontWeight={450}>{percentageFormatter(rate)}</Typography>
                </Box>

                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                >
                    <Typography textAlign='left' fontSize={14} fontWeight={450}>Interest</Typography>
                    <Typography textAlign='left' fontSize={14} fontWeight={450}>{currencyFormatter(interest)}</Typography>
                </Box>

                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                >
                    <Typography textAlign='left' fontSize={14} fontWeight={450}>Total</Typography>
                    <Typography textAlign='left' fontSize={14} fontWeight={450}>{currencyFormatter(totalAmount)}</Typography>
                </Box>
            </Box>
        </Box >
    )
}