import axios from 'axios';
import { Company, Approval, RateCurve } from '@lendica/utils';

export const getCompanyDetails = async () => {
    const response = await axios.get<Company>('/company/details');
    return response.data;
};

export const getApprovalList = async () => {
    const response = await axios.get<Partial<Approval>[]>('/company/approvals');
    return response.data;
};

export const getApprovalDetails = async (approval_id: string) => {
    const response = await axios.get(`/company/approvals?id=${approval_id}`);
    return response.data;
};

export const getRateCurves = async () => {
    const response = await axios.get<RateCurve[]>('/company/ratecurve_backbones');
    return response.data;
};
