import { useEffect, useState, useCallback } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box, Button, Typography, Card } from '@mui/material';
import { Dropzone } from '@lendica/components';

export const UnableToConnect = ({
    company_id,
    caption,
    secondaryCaption,
    uploadedFiles,
    fileUploadHandler,
    type,
    env,
}) => {
    const [expanded, setExpanded] = useState(false);

    return (
        <Accordion
            expanded={expanded}
            onChange={() => setExpanded(!expanded)}
            sx={{
                '&:before': {
                    display: 'none',
                },
                'borderRadius': 1,
                'boxShadow': 'none',
                'paddingTop': 1,
                'background': '##EDEDF4',
                'border': '1px solid #EDEDF4',
                'paddingBottom': expanded ? 0 : 1,
                'marginY': 1,
            }}
        >
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                color="#FFF"
                sx={{
                    border: 'none',
                    boxShadow: 'none',
                    margin: 0,
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        margin: 'none',
                    }}
                >
                    <Typography variant="h5" fontSize={14} fontWeight={500} color="primary">
                        {caption}
                    </Typography>
                </Box>
            </AccordionSummary>

            <AccordionDetails sx={{ padding: 0 }}>
                <Box
                    sx={{
                        marginX: 2,
                        marginBottom: 1,
                    }}
                >
                    <Typography variant="caption">{secondaryCaption}</Typography>
                    <Dropzone
                        value={uploadedFiles}
                        onChange={value => {
                            fileUploadHandler(value);
                        }}
                        onDrop={() => {
                            console.log('dropped');
                        }}
                        accept={{
                            'image/png': ['.png'],
                            'image/jpg': ['.jpg'],
                            'image/jpeg': ['.jpeg'],
                            'application/pdf': ['.pdf'],
                            'text/csv': ['.csv'],
                            'application/json': ['.json'],
                        }}
                        acceptHelper="Accepting pdf, images, csv and json."
                        label="+ New"
                        api={{
                            presignedUrl: 'https://micro-awsmanager.herokuapp.com/s3/signed-url',
                            getObjectKey(fileName) {
                                const ext = fileName.split('.').pop();
                                return `${env}/${company_id}/${type}/${type}_${new Date().valueOf()}.${ext}`;
                            },
                            presignedUrlParams: {
                                bucket_name: 'lendica-pod',
                            },
                        }}
                    />
                </Box>
            </AccordionDetails>
        </Accordion>
    );
};
