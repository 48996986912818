import { FC, useState, useEffect } from 'react';
import { TextField, Autocomplete, Alert } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { SupportAgent, Check } from '@mui/icons-material';

import { FormikTextField } from '@lendica/components';
import { useField } from 'formik';

import { BankAccount, CompanyBankStatus } from './bankTypes';
import { BankService } from './BankService';
import { Company, maskAccountNumber } from '@lendica/utils';

export const BankSupportButton = ({
    company,
    invoiceOrBill,
    invoiceId,
}: {
    company: Company;
    invoiceOrBill: 'Invoice' | 'Bill';
    invoiceId: string;
}) => {
    const [status, setStatus] = useState('idle');

    const contactSupport = async () => {
        try {
            setStatus('pending');
            await BankService.contactSupport(
                `🤯 ${company.company_name} needs help with their bank account when activating a deal.`,
                `Company: ${company.company_name} \n Company ID: ${company.id} \n ${invoiceOrBill} ID: ${invoiceId} \n First Name: ${company.first_name} \n Last Name: ${company.last_name} \n Email: ${company.email} \n Phone: ${company.phone_number}`
            );
            setStatus('success');
        } catch (e) {
            console.log(e);
            setStatus('error');
        }
    };

    return (
        <LoadingButton
            variant="text"
            loading={status === 'pending'}
            endIcon={status === 'success' ? <Check /> : <SupportAgent />}
            onClick={contactSupport}
            color={status === 'success' ? 'success' : status === 'error' ? 'error' : 'primary'}
        >
            {status === 'success'
                ? `Request sent`
                : status === 'error'
                ? `Try again`
                : `Contact support`}
        </LoadingButton>
    );
};

const ManualBankInput = () => {
    return (
        <>
            <FormikTextField
                name="company.bank_name"
                label="Bank Name"
                fullWidth
                required
                type="string"
            />
            <FormikTextField
                name="company.bank_account"
                label="Account Number"
                fullWidth
                required
                type="string"
            />
            <FormikTextField
                name="company.bank_routing"
                label="Routing Number"
                fullWidth
                required
                type="string"
            />
        </>
    );
};

export const CompanyBankSelect: FC<{
    bankStatus: CompanyBankStatus;
    bankAccounts: BankAccount[];
}> = ({ bankStatus, bankAccounts }) => {
    const [selectedBank, setSelectedBank] = useState<BankAccount | null>(null);
    const [bankNameFeild, bankNameMeta, bankNameHelper] = useField('company.bank_name');
    const [accountFeild, accountMeta, accountHelper] = useField('company.bank_account');
    const [routingFeild, routingMeta, routingHelper] = useField('company.bank_routing');

    const displayBank = (bank: BankAccount) => {
        return `${bank!.bank_name!} - ${maskAccountNumber(bank!.bank_account! || '')}`;
    };

    const setFormikValues = async (bank: BankAccount) => {
        if (!bank) {
            bankNameHelper.setValue(null);
            accountHelper.setValue(null);
            routingHelper.setValue(null);
            return false;
        }
        bankNameHelper.setValue(bank!.bank_name!);
        accountHelper.setValue(bank!.bank_account!);
        routingHelper.setValue(bank!.bank_routing!);
        return true;
    };

    return bankStatus === CompanyBankStatus.HAS_DEFAULT ||
        bankStatus === CompanyBankStatus.HAS_SINGLE_ACTIVE_CONNECTED ? (
        <TextField
            size="small"
            label="Your Bank Account"
            type="string"
            fullWidth
            required
            value={displayBank(bankAccounts[0])}
            disabled
            helperText="If you need to change your bank account, please contact support."
        />
    ) : bankStatus === CompanyBankStatus.HAS_MULTIPLE_ACTIVE_CONNECTED ? (
        <>
            <Alert sx={{ mb: 2 }} severity={selectedBank ? 'info' : 'warning'}>
                Select a connected bank account to use for payments.
            </Alert>
            {bankAccounts!.length! > 0 && (
                <Autocomplete
                    size="small"
                    value={selectedBank}
                    onChange={(event, newValue) => {
                        setSelectedBank(newValue);
                        setFormikValues(newValue!);
                    }}
                    isOptionEqualToValue={(option, value) =>
                        option.bank_name === value.bank_name &&
                        option.bank_account === value.bank_account
                    }
                    selectOnFocus
                    clearOnBlur
                    handleHomeEndKeys
                    disablePortal
                    options={bankAccounts}
                    getOptionLabel={(option: BankAccount) => displayBank(option)}
                    // renderOption={(props, option) => <li {...props}>{displayBank(option)}</li>}
                    renderInput={params => (
                        <TextField
                            {...params}
                            label="Select Your Bank Account"
                            type="string"
                            fullWidth
                            required
                            helperText={
                                // bankNameMeta.error ||
                                // accountMeta.error ||
                                // routingMeta.error ||
                                `If you need to add a different account, please contact support.`
                            }
                        />
                    )}
                />
            )}
        </>
    ) : (
        <ManualBankInput />
    );
};
