import { Logo } from "@lendica/components"
import { Box } from "@mui/system"
import { OfferTerms } from "../components/OfferTerms"
import { MarketingCard } from "../components/MarketingCard";
import { BreadCrumbs } from "../components/ShippingBreadCrumbs";
import { useNavigate, useParams } from "react-router-dom";
import { RoundedButton } from "@lendica/components";
import { ShippingInfoForm } from "../forms/ShippingInfoForm";
import { useEffect, useState } from "react";
import * as api from '../api/index'

export const ShippingInfoPage = () => {

    const { bill_id } = useParams()

    const [billDetails, setBillDetails] = useState([])

    useEffect(() => {
        const getBillDetails = async () => {
            const res = await api.getBillDetails(bill_id)
            setBillDetails(res)
        }
        getBillDetails()
    }, [])

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', minWidth: 350, width: '100%', maxWidth: 600, paddingX: 2, boxSizing: 'border-box' }}>
            <Logo height={20} />
            <BreadCrumbs page={1} />
            <ShippingInfoForm billId={bill_id} billDetails={billDetails} />
        </Box>
    )
}