import { useEffect } from 'react';
import { Route, Routes, useNavigate, Navigate, useSearchParams } from 'react-router-dom';
import axios from 'axios';
import { PhoneAuth, ProtectedRoute, useAccountContext, AccountProvider } from '@lendica/auth';
import { baseURL } from './api/axiosInstance';
import { AppContainer } from './AppContainer';

const PhoneAuthContainer = ({ onAuthSuccess, onAccountClear }) => {
    const { storePortedTokens, clearAccounts } = useAccountContext();
    // const isConfigured = useConfigureAxios();

    const onRequestError = async e => {
        if (e.response && e.response.status >= 400) {
            console.log('onRequestError', e.response.status);
            await new Promise(async resolve => {
                await clearAccounts();
                setTimeout(() => resolve('removed!'), 500);
            }).then(() => {
                onAccountClear();
            });
            return Promise.resolve('cleared');
        }
        return Promise.reject(e);
    };

    useEffect(() => {
        axios.interceptors.response.use(response => response, onRequestError);
    }, []);

    return (
        <PhoneAuth
            baseURL={baseURL}
            onSuccess={async tokens => {
                await storePortedTokens(tokens);
                onAuthSuccess();
            }}
        />
    );
};

export const App = () => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    const handleAuthSuccess = () => {
        navigate(`/application?${searchParams.toString()}`);
    };

    const handleAccountClear = () => {
        navigate(`/login?${searchParams.toString()}`);
    };

    return (
        <AccountProvider onAuthSuccess={handleAuthSuccess} onAccountClear={handleAccountClear}>
            <Routes>
                <Route element={<ProtectedRoute />}>
                    <Route path="*" element={<AppContainer />} />
                </Route>
                <Route
                    path="/login/*"
                    element={
                        <PhoneAuthContainer
                            onAuthSuccess={handleAuthSuccess}
                            onAccountClear={handleAccountClear}
                        />
                    }
                />
                <Route path="/" element={<Navigate to={`/login?${searchParams.toString()}`} />} />
            </Routes>
        </AccountProvider>
    );
};
