import { useEffect, useState } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box, Typography } from '@mui/material';
import * as api from '../api/index';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { DropzoneFile } from '@lendica/components';
import { QuickbooksLogo } from '@lendica/components';
import ReceiptIcon from '@mui/icons-material/Receipt';
import { UnableToConnect } from './UnableToConnect';
import SyncAltIcon from '@mui/icons-material/SyncAlt';
import ModeStandby from '@mui/icons-material/ModeStandby';

export const Quickbooks = ({ company_id, env }: { company_id: string; env: string }) => {
    const [expanded, setExpanded] = useState(false);
    const [uploadedFiles, setUploadedFiles] = useState<DropzoneFile[]>([]);
    const [connections, setConnections] = useState([])
    const [popupClosed, setPopupClosed] = useState(false);
    const [accountConnected, setAccountConnected] = useState(false);

    function launchPopup() {
        var win: any;
        var parameters = 'location=1,width=800,height=650';
        parameters += ',left=' + (screen.width - 800) / 2 + ',top=' + (screen.height - 650) / 2;

        setPopupClosed(false)
        // Launch Popup
        if (env === 'idev') {
            win = window.open(
                `https://micro-oauth-dev.herokuapp.com/oauth/quickbooks?company_id=${company_id}`,
                'connectPopup',
                parameters
            );
        } else if (env === 'sandbox') {
            win = window.open(
                `https://micro-oauth-sandbox.herokuapp.com/oauth/quickbooks?company_id=${company_id}`,
                'connectPopup',
                parameters
            );
        } else if (env === 'prod') {
            win = window.open(
                `https://oauth.app.golendica.com/oauth/quickbooks?company_id=${company_id}`,
                'connectPopup',
                parameters
            );
        }

        let interval = setInterval(() => {
            if (win.closed) {
                setPopupClosed(true);
                clearInterval(interval);
            }
        }, 1000);
    }

    const handleFileUpload = (file: any) => {
        setUploadedFiles(file);
    };

    useEffect(() => {
        const getConnections = async () => {
            const res = await api.getDataConnections()
            let isConnected = res.find((x: any) => x.active === true && x.saas_name === 'quickbooks')
            setAccountConnected(isConnected)
        }
        getConnections()
    }, [popupClosed]);

    const handleClick = () => {
        if (!accountConnected) {
            launchPopup()
        }
    }

    return (
        <Accordion
            expanded={expanded}
            onChange={() => setExpanded(!expanded)}
            sx={{
                '&:before': {
                    display: 'none',
                },
                'borderRadius': 1,
                'boxShadow': 'none',
                'paddingTop': 1,
                'background': '##EDEDF4',
                'border': '1px solid #EDEDF4',
                'mt': 2,
                'mb': 2,
                'paddingBottom': expanded ? 0 : 1,
            }}
            data-testid="accounting-accordion"
        >
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                color="#FFF"
                sx={{
                    border: 'none',
                    boxShadow: 'none',
                }}
            >
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <ReceiptIcon />
                    <Typography fontWeight={500} ml={2}>
                        Accounting
                    </Typography>
                </Box>

                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        paddingX: '1rem',
                        alignItems: 'center',
                        width: '100%',
                    }}
                >
                    {accountConnected && <CheckCircleIcon color="success" />}
                </Box>
            </AccordionSummary>

            <AccordionDetails
                sx={{
                    padding: '0',
                }}
            >
                <Box>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            padding: '1rem 1rem',
                            background: '#f2f2f8',
                            borderRadius: 1,
                            marginY: 1,
                            marginX: 2,
                            cursor: 'pointer',
                        }}
                        component="div"
                        onClick={() => {
                            handleClick()
                        }}
                        data-testid="open-quickbooks-popup"
                    >
                        <QuickbooksLogo />
                        {accountConnected ?
                            <Box sx={{ display: 'flex' }}>
                                <Typography mr={1}>connected</Typography>
                                <ModeStandby color="success" />
                            </Box> :
                            <SyncAltIcon />
                        }
                    </Box>

                    <Box
                        sx={{
                            marginX: 2,
                            marginBottom: 1,
                        }}
                    >
                        <UnableToConnect
                            company_id={company_id}
                            caption={"I'm not using QuickBooks Online."}
                            secondaryCaption={'Upload last years financial statements'}
                            uploadedFiles={uploadedFiles}
                            fileUploadHandler={handleFileUpload}
                            type={'accounting'}
                            env={env}
                        />
                    </Box>
                </Box>

                {/* <Box
                    sx={{ height: 'fit-content', display: 'flex', flexDirection: 'column', background: 'background.card' }}
                >
                    <UploadList
                        connectedAccounts={connectedAccounts}
                        uploadedFiles={uploadedFiles}
                    />
                </Box> */}
            </AccordionDetails>
        </Accordion>
    );
};
