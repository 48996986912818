import { Box, TextField } from '@mui/material';
import { useState } from 'react';
import { makeStyles } from '@mui/styles';
import { useFormik } from 'formik';
import * as yup from 'yup';
import NumberFormat from 'react-number-format';
import { RoundedButton } from '@lendica/components';
import { PlaceAutocomplete } from '../components/PlaceAutocomplete';
import * as api from '../api/index';
import { formatDate, isBeforeToday, isValidDate } from '../utils/validation';

const useStyles = makeStyles(theme => ({
    row: {
        display: 'flex',
        gap: '1rem',
        flexDirection: 'row',

        [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
        },
    },
}));

const validationSchema = yup.object({
    company_legal_name: yup.string('Enter your company name').required('Company name is required'),
    business_ein: yup
        .string('Please enter your 9 digit business EIN')
        .min(9, 'Business EIN must be 9 digits long')
        .required('Business EIN is required'),
    ownership_percent: yup
        .number('Ownership must be a number')
        .min(0, 'Ownership cannot be below 0%')
        .max(100, 'Ownership cannot exceed 100%'),
    date_of_incorporation: yup
        .string()
        .test('test-date', 'Please enter a valid date', value => isValidDate(value))
        .test('test-date', 'Please enter a date prior to today', value => isBeforeToday(value)),
    monthly_debt_pmt: yup
        .number('')
        .required('Please enter your total amount in monthly debt payments'),
    // .min(0),
    monthly_revenue: yup.number().required('Please enter your monthly revenue'),
    // .min(0),
    website: yup.string(),
    company_address: yup.string().required('Company address is required'),
});

export const BusinessInfoForm = ({
    application,
    handleApplicationChange,
    handleNext,
    handleBack,
}) => {
    const formik = useFormik({
        initialValues: {
            company_legal_name: application.company_legal_name || '',
            business_ein: application.business_ein || '',
            ownership_percent: application.ownership_percent || '',
            date_of_incorporation: application.date_of_incorporation || '',
            monthly_debt_pmt: application.monthly_debt_pmt || '',
            monthly_revenue: application.monthly_revenue || '',
            website: application.website || '',
            company_address: application.company_address || '',
        },
        validationSchema: validationSchema,
        onSubmit: async values => {
            await api.postApplication({
                ...values,
                ownership_percent: values.ownership_percent === '' ? 0 : values.ownership_percent,
                monthly_debt_pmt: values.monthly_debt_pmt === '' ? 0 : values.monthly_debt_pmt,
                date_of_incorporation: formatDate(values.date_of_incorporation, 'MM/dd/yyyy'),
            });
            handleApplicationChange(values);
            handleNext();
        },
    });

    // const handleAddressChange = address => {
    //     formik.setFieldValue('company_address', address.description);
    // };

    const getAddressValues = address => {
        let address_values = {
            city: '',
            state: '',
        };

        let terms_length = address.terms.length;

        address_values['city'] = address.terms[terms_length - 3].value;
        address_values['state'] = address.terms[terms_length - 2].value;
        return address_values;
    };

    const handleAddressChange = async address => {
        let geocoded_address = await api.geocodeAddress(address.place_id);
        let zip_code = geocoded_address.address_components.find(x =>
            x.types.includes('postal_code')
        ).long_name;

        let { city, state } = getAddressValues(address);

        formik.setFieldValue('company_address', address.description);
        // formik.setFieldValue('address', address.structured_formatting.main_text);
        // formik.setFieldValue('city', city);
        // formik.setFieldValue('province', getState(state));
        // formik.setFieldValue('province_code', state);
        // formik.setFieldValue('zip_code', zip_code);
    };

    const classes = useStyles();

    return (
        <form onSubmit={formik.handleSubmit} style={{ margin: '1rem 0' }}>
            <Box className={classes.row} my={2}>
                <TextField
                    label="Company Legal Name"
                    name="company_legal_name"
                    id="company_legal_name"
                    value={formik.values.company_legal_name}
                    onChange={formik.handleChange}
                    error={
                        formik.touched.company_legal_name &&
                        Boolean(formik.errors.company_legal_name)
                    }
                    helperText={
                        formik.touched.company_legal_name && formik.errors.company_legal_name
                    }
                    size="small"
                    fullWidth
                    sx={{ flex: 4 }}
                    color="primary"
                    inputProps={{ 'data-testid': 'company_legal_name' }}
                />
                <NumberFormat
                    customInput={TextField}
                    format="##-#######"
                    label="Business EIN"
                    name="business_ein"
                    id="business_ein"
                    value={formik.values.business_ein}
                    onValueChange={({ value }) => {
                        formik.setFieldValue('business_ein', value);
                    }}
                    error={formik.touched.business_ein && Boolean(formik.errors.business_ein)}
                    helperText={formik.touched.business_ein && formik.errors.business_ein}
                    size="small"
                    fullWidth
                    sx={{ flex: 2 }}
                    color="primary"
                    inputProps={{ 'data-testid': 'business_ein' }}
                />
            </Box>

            <Box className={classes.row} mb={2}>
                <NumberFormat
                    customInput={TextField}
                    thousandSeparator={true}
                    // format={'###%'}
                    suffix={'%'}
                    label="Ownership Percent"
                    name="ownership_percent"
                    id="ownership_percent"
                    value={formik.values.ownership_percent}
                    onValueChange={({ floatValue }) => {
                        if (floatValue) {
                            console.log(floatValue);
                            formik.setFieldValue('ownership_percent', floatValue);
                        } else {
                            formik.setFieldValue('ownership_percent', 0);
                        }
                    }}
                    decimalSeparator="."
                    error={
                        formik.touched.ownership_percent && Boolean(formik.errors.ownership_percent)
                    }
                    helperText={formik.touched.ownership_percent && formik.errors.ownership_percent}
                    size="small"
                    fullWidth
                    sx={{ flex: 1 }}
                    color="primary"
                    inputProps={{ 'data-testid': 'ownership_percent' }}
                />
                <NumberFormat
                    customInput={TextField}
                    format="##/##/####"
                    placeholder="MM/DD/YYYY"
                    mask={['M', 'M', 'D', 'D', 'Y', 'Y', 'Y', 'Y']}
                    label="Date of Incorporation"
                    name="date_of_incorporation"
                    id="date_of_incorporation"
                    onChange={formik.handleChange}
                    value={formik.values.date_of_incorporation}
                    error={
                        formik.touched.date_of_incorporation &&
                        Boolean(formik.errors.date_of_incorporation)
                    }
                    helperText={
                        formik.touched.date_of_incorporation && formik.errors.date_of_incorporation
                    }
                    size="small"
                    fullWidth
                    sx={{ flex: 2 }}
                    color="primary"
                    inputProps={{ 'data-testid': 'date_of_incorporation' }}
                />
            </Box>

            <Box className={classes.row} mb={2}>
                <NumberFormat
                    customInput={TextField}
                    thousandSeparator={true}
                    prefix={'$'}
                    label="Current Monthly Debt Payment"
                    name="monthly_debt_pmt"
                    id="monthly_debt_pmt"
                    onValueChange={({ floatValue }) => {
                        formik.setFieldValue('monthly_debt_pmt', floatValue);
                    }}
                    value={formik.values.monthly_debt_pmt}
                    error={
                        formik.touched.monthly_debt_pmt && Boolean(formik.errors.monthly_debt_pmt)
                    }
                    helperText={formik.touched.monthly_debt_pmt && formik.errors.monthly_debt_pmt}
                    size="small"
                    fullWidth
                    sx={{ flex: 1 }}
                    color="primary"
                    inputProps={{ 'data-testid': 'monthly_debt_pmt' }}
                />
                <NumberFormat
                    customInput={TextField}
                    thousandSeparator={true}
                    prefix={'$'}
                    label="Monthly Revenue"
                    name="monthly_revenue"
                    id="monthly_revenue"
                    onValueChange={({ floatValue }) => {
                        formik.setFieldValue('monthly_revenue', floatValue);
                    }}
                    value={formik.values.monthly_revenue}
                    error={formik.touched.monthly_revenue && Boolean(formik.errors.monthly_revenue)}
                    helperText={formik.touched.monthly_revenue && formik.errors.monthly_revenue}
                    size="small"
                    fullWidth
                    sx={{ flex: 1 }}
                    color="primary"
                    inputProps={{ 'data-testid': 'monthly_revenue' }}
                />
            </Box>

            <Box sx={{ display: 'flex' }} mb={2}>
                <TextField
                    label="Website"
                    name="website"
                    id="website"
                    onChange={formik.handleChange}
                    value={formik.values.website}
                    error={formik.touched.website && Boolean(formik.errors.website)}
                    helperText={formik.touched.website && formik.errors.website}
                    size="small"
                    fullWidth
                    sx={{ flex: 1 }}
                    color="primary"
                    inputProps={{ 'data-testid': 'website' }}
                />
            </Box>

            <Box sx={{ display: 'flex' }} mb={2}>
                <PlaceAutocomplete
                    label="Company Address"
                    onChange={handleAddressChange}
                    value={formik.values.company_address}
                    error={formik.touched.company_address && Boolean(formik.errors.company_address)}
                    helperText={formik.touched.company_address && formik.errors.company_address}
                />
            </Box>

            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                <RoundedButton
                    sx={{
                        flex: 1,
                        mt: 1,
                        mr: 1,
                        borderColor: 'primary.light',
                        border: '1px solid',
                        color: 'primary.main',
                    }}
                    onClick={handleBack}
                >
                    Back
                </RoundedButton>
                <RoundedButton
                    sx={{ flex: 1, mt: 1 }}
                    variant="contained"
                    color="primary"
                    type="submit"
                >
                    Continue
                </RoundedButton>
            </Box>
        </form>
    );
};
