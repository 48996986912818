import { Logo } from '@lendica/components';
import { Box } from '@mui/system';
import LoadingButton from '@mui/lab/LoadingButton';
import { MarketingCard } from '../components/MarketingCard';
import { BreadCrumbs } from '../components/BreadCrumbs';
import { useParams } from 'react-router-dom';
import { Plaid } from '../components/Plaid';
import { Quickbooks } from '../components/Accounting';
import { ECommerce } from '../components/ECommerce';
import { PointOfSales } from '../components/PointOfSales';
import { useState, useEffect } from 'react';
import * as api from '../api/index';

export const DataConnectionPage = ({ onComplete, env }) => {
    const { company_id } = useParams();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [application, setApplication] = useState({});
    const [connected, setConnected] = useState(false);

    const [connectedAccounts, setConnectedAccounts] = useState([]);

    const handleConnection = () => {
        setConnected(true);
    };

    useEffect(() => {
        (async function () {
            const currentApplication = await api.getApplication();
            setApplication(currentApplication);
        })();
    }, []);

    useEffect(() => {
        (async function () {
            const dataConnections = await api.getDataConnections();
            setConnectedAccounts(dataConnections);
        })();
    }, []);

    const onSubmit = () => {
        setIsSubmitting(true);
        setTimeout(async () => {
            const application = await api.postApplication({ final: true });
            onComplete(application.status, company_id);
            setIsSubmitting(false);
        }, 2000);
        // TODO: once lens connection is updated, remove manual timeout
    };

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                minWidth: 350,
                width: '100%',
                maxWidth: 600,
                paddingX: 2,
                boxSizing: 'border-box',
            }}
        >
            <Logo height={20} />
            <BreadCrumbs page={2} company_id={company_id} />
            <MarketingCard activeStep={0} page={2} />

            <Plaid company_id={company_id} handleConnection={handleConnection} env={env} />

            <Quickbooks company_id={company_id} env={env} />

            {/* <ECommerce company_id={company_id} env={env} />

            <PointOfSales company_id={company_id} env={env} /> */}

            <LoadingButton
                size="large"
                loading={isSubmitting}
                variant="contained"
                color="primary"
                sx={{
                    flex: 1,
                    mt: 2,
                    mb: 4,
                }}
                disabled={!connected}
                onClick={() => {
                    // get application status here and pass to on complete function
                    //const status = (await getApplication).data.application_status
                    onSubmit();
                }}
                data-testid="data-connections-submit-button"
            >
                Submit
            </LoadingButton>
        </Box>
    );
};
