import { Logo } from '@lendica/components';
import { MarketingCard } from '../components/MarketingCard';
import { RoundedButton } from '@lendica/components';
import { Box, TextField, Typography } from '@mui/material';
import { useEffect } from 'react';
import * as api from '../api/index';
import { useState } from 'react';


export const ApplicationSubmittedPage = ({ env }) => {
    const [company, setCompany] = useState({});

    const getPortalUrl = () => {
        if (env === 'idev') {
            return 'https://portal.app-idev.golendica.com'
        } else {
            return 'https://portal.app.golendica.com'
        }
    }

    useEffect(() => {
        (async function () {
            let companySummary = await api.getCompany();
            setCompany(companySummary);
        })();
    }, []);

    let portalUrl = env === 'idev' ?
        'https://portal.app-idev.golendica.com' :
        'https://portal.app.golendica.com'

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                minWidth: 350,
                width: '100%',
                maxWidth: 600,
                paddingX: 2,
                boxSizing: 'border-box',
            }}
        >
            <Logo height={20} />
            {/* <BreadCrumbs page={3} company_id={company_id} /> */}
            {/* <MarketingCard page={3} /> */}

            <Box
                sx={{
                    height: 'fit-content',
                    width: '100%',
                    padding: '1.5rem 1rem',
                    background: '#f2f2f8',
                    borderRadius: 2,
                    boxSizing: 'border-box',
                    margin: '2rem 0'
                }}
            >
                <Typography variant="h5">Congrats! Your application has been submitted. Please check your email for further instruction.</Typography>
            </Box>

            <RoundedButton
                variant="contained"
                color="primary"
                sx={{
                    flex: 1,
                    mt: 2,
                    height: 'fit-content',
                }}
                type="submit"
                href={portalUrl}
            >
                Go to portal
            </RoundedButton>
        </Box>
    );
};
