import { Box, TextField, Alert, AlertTitle, Button } from '@mui/material';
import React, { useState } from 'react';
import NumberFormat from 'react-number-format';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { makeStyles } from '@mui/styles';
import { PlaceAutocomplete } from '../components/PlaceAutocomplete';
import { RoundedButton, RoundedLoadingButton } from '@lendica/components';
import { formatDate, isValidDate, isBeforeToday } from '../utils/validation';
import * as api from '../api/index';
import { useNavigate } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
    row: {
        display: 'flex',
        gap: '1rem',
        flexDirection: 'row',

        [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
        },
    },
}));

export const AdditionalInfoForm = ({
    application,
    handleApplicationChange,
    handleBack,
    companyId,
}) => {
    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();

    const hitsRevenueThreshold = application.monthly_revenue >= 500000;

    let validationSchema;
    let formik;

    if (hitsRevenueThreshold) {
        validationSchema = yup.object({
            additional_owners: yup.number('Please enter a valid number'),
            business_licenses: yup.string(),
        });

        formik = useFormik({
            initialValues: {
                additional_owners: application.additional_owners || '',
                business_licenses: application.business_licenses || '',
            },
            validationSchema: validationSchema,
            onSubmit: values => {
                setLoading(true);
                setTimeout(async () => {
                    await api.postApplication({
                        ...values,
                        additional_owners:
                            values.additional_owners === '' ? 0 : values.additional_owners,
                    });
                    handleApplicationChange(values);
                    navigate(`../data-connection/${companyId}`);
                }, 1500);
            },
        });
    } else {
        validationSchema = yup.object({
            ssn: yup
                .string('Enter your 9 digit SSN')
                .min(9, 'SSN must be 9 digits long')
                .required('SSN is required'),
            personal_credit_score: yup
                .number()
                .min(300, 'Credit scores can range between 300-850')
                .max(850, 'Credit scores can range between 300-850')
                .required('Credit score is required.'),
            date_of_birth: yup
                .string()
                .test('test-date', 'Please enter a valid date', value => isValidDate(value))
                .test('test-date', 'Please enter a date prior to today', value =>
                    isBeforeToday(value)
                ),
            additional_owners: yup.number('Please enter a valid number'),
            home_address: yup.string().required('Home address is required'),
            business_licenses: yup.string(),
        });

        formik = useFormik({
            initialValues: {
                ssn: application.ssn || '',
                personal_credit_score: application.personal_credit_score || '',
                date_of_birth: application.date_of_birth || '',
                additional_owners: application.additional_owners || '',
                home_address: application.home_address || '',
                business_licenses: application.business_licenses || '',
            },
            validationSchema: validationSchema,
            onSubmit: values => {
                setLoading(true);
                setTimeout(async () => {
                    await api.postApplication({
                        ...values,
                        additional_owners:
                            values.additional_owners === '' ? 0 : values.additional_owners,
                        date_of_birth: formatDate(values.date_of_birth, 'MM/dd/yyyy'),
                    });
                    handleApplicationChange(values);
                    navigate(`../data-connection/${companyId}`);
                }, 1500);
            },
        });
    }

    const handleAddressChange = address => {
        formik.setFieldValue('home_address', address.description);
    };

    const classes = useStyles();

    return (
        <form onSubmit={formik.handleSubmit}>
            {!hitsRevenueThreshold && (
                <Box my={2}>
                    <Alert severity="success">
                        <AlertTitle>Why does Lendica need my SSN?</AlertTitle>
                        We perform a soft check on your credit — providing SSN{' '}
                        <strong>won't impact</strong> your credit score!
                    </Alert>
                    <Box className={classes.row} my={2}>
                        <NumberFormat
                            customInput={TextField}
                            format="###-##-####"
                            label="SSN"
                            name="ssn"
                            id="ssn"
                            value={formik.values.ssn}
                            onValueChange={({ value }) => {
                                formik.setFieldValue('ssn', value);
                            }}
                            error={formik.touched.ssn && Boolean(formik.errors.ssn)}
                            helperText={formik.touched.ssn && formik.errors.ssn}
                            size="small"
                            fullWidth
                            sx={{ flex: 1 }}
                            color="primary"
                        />
                        <NumberFormat
                            customInput={TextField}
                            format="###"
                            label="Personal Credit Score"
                            name="personal_credit_score"
                            id="personal_credit_score"
                            value={formik.values.personal_credit_score}
                            onChange={formik.handleChange}
                            error={
                                formik.touched.personal_credit_score &&
                                Boolean(formik.errors.personal_credit_score)
                            }
                            helperText={
                                formik.touched.personal_credit_score &&
                                formik.errors.personal_credit_score
                            }
                            size="small"
                            fullWidth
                            sx={{ flex: 1 }}
                            color="primary"
                        />
                    </Box>
                </Box>
            )}

            <Box className={classes.row} mb={2} mt={hitsRevenueThreshold ? 2 : 0}>
                {!hitsRevenueThreshold && (
                    <NumberFormat
                        customInput={TextField}
                        format="##/##/####"
                        placeholder="MM/DD/YYYY"
                        mask={['M', 'M', 'D', 'D', 'Y', 'Y', 'Y', 'Y']}
                        label="Date of Birth"
                        name="date_of_birth"
                        id="date_of_birth"
                        value={formik.values.date_of_birth}
                        onChange={formik.handleChange}
                        error={formik.touched.date_of_birth && Boolean(formik.errors.date_of_birth)}
                        helperText={formik.touched.date_of_birth && formik.errors.date_of_birth}
                        size="small"
                        fullWidth
                        sx={{ flex: 1 }}
                        color="primary"
                    />
                )}
                <TextField
                    label="No. of Additional Owners"
                    name="additional_owners"
                    id="additional_owners"
                    value={formik.values.additional_owners}
                    onChange={formik.handleChange}
                    error={
                        formik.touched.additional_owners && Boolean(formik.errors.additional_owners)
                    }
                    helperText={formik.touched.additional_owners && formik.errors.additional_owners}
                    size="small"
                    fullWidth
                    sx={{ flex: 1 }}
                    color="primary"
                />
            </Box>

            {!hitsRevenueThreshold && (
                <Box sx={{ display: 'flex' }} mb={2} mt={2}>
                    <PlaceAutocomplete
                        label="Home Address"
                        onChange={handleAddressChange}
                        value={formik.values.home_address}
                        error={formik.touched.home_address && Boolean(formik.errors.home_address)}
                        helperText={formik.touched.home_address && formik.errors.home_address}
                    />
                </Box>
            )}

            <Box sx={{ display: 'flex' }} mb={2}>
                <TextField
                    label="Business License Numbers (optional)"
                    name="business_licenses"
                    id="business_licenses"
                    value={formik.values.business_licenses}
                    onChange={formik.handleChange}
                    error={
                        formik.touched.business_licenses && Boolean(formik.errors.business_licenses)
                    }
                    helperText={
                        (formik.touched.business_licenses && formik.errors.business_licenses) ||
                        'Seperate your business licences by space or comma'
                    }
                    fullWidth
                    rows="3"
                    multiline
                    color="primary"
                />
            </Box>

            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                <RoundedButton
                    sx={{
                        flex: 1,
                        mt: 1,
                        mr: 1,
                        borderColor: 'primary.light',
                        border: '1px solid',
                        color: 'primary.main',
                    }}
                    onClick={handleBack}
                >
                    Back
                </RoundedButton>
                <RoundedLoadingButton
                    variant="contained"
                    color="primary"
                    type="submit"
                    sx={{ flex: 1, mt: 1 }}
                    loading={loading}
                >
                    Next: Data Connection
                </RoundedLoadingButton>
            </Box>
        </form>
    );
};
