import { useEffect, useState, useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import { Box, Stepper, Step, StepLabel, StepContent, CircularProgress } from '@mui/material';
import { Logo } from '@lendica/components';
import { PersonalInfoForm } from '../forms/PersonalInfoForm';
import { BusinessInfoForm } from '../forms/BusinessInfoForm';
import { AdditionalInfoForm } from '../forms/AdditionalInfoForm';
import { MarketingCard } from '../components/MarketingCard';
import { BreadCrumbs } from '../components/BreadCrumbs';
import { formatDate } from '../utils/validation';

import * as api from '../api/index';
import { useApplication } from '../context/useApplication';

const steps = [
    { label: 'A little bit about yourself', key: 'personal' },
    { label: "What's your business like?", key: 'business' },
    { label: 'Let’s get connected', key: 'additional' },
];

const getIpAddress = async () => {
    const res = await fetch('https://api.ipify.org/?format=json');
    const { ip } = await res.json();
    return ip;
};

const getFingerprint = async () => {
    let { ica_fp } = window;
    if (ica_fp) {
        return ica_fp;
    }
    ica_fp = {
        browserName: navigator.userAgent,
        os: navigator.platform,
        screenResolution: `${screen.width}x${screen.height}`,
        language: navigator.language,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        geo: btoa(await getIpAddress()),
        allowCookies: navigator.cookieEnabled,
        doNotTrack: navigator.doNotTrack,
    };
    window.ica_fp = ica_fp;
    return ica_fp;
};

export const ApplicationPage = ({ onComplete }) => {
    const [dataLoaded, setDataLoaded] = useState(false);
    const [application, setApplication] = useState({
        id: '',
        first_name: '',
        last_name: '',
        home_address: '',
        email: '',
        password: '',
        phone_number: '',
        company_legal_name: '',
        business_ein: '',
        date_of_incorporation: '',
        ownership_percent: '',
        monthly_debt_pmt: '',
        monthly_revenue: '',
        company_address: '',
        website: '',
        ssn: '',
        personal_credit_score: '',
        date_of_birth: '',
        additional_owners: '',
        business_licenses: '',
        meta: {},
    });
    const [company, setCompany] = useState({});
    const [activeStep, setActiveStep] = useState(0);

    const { searchParams } = useApplication();

    useEffect(() => {
        (async function () {
            const currentApplication = await api.getApplication();
            let companyInfo = await api.getCompany();

            if (currentApplication.date_of_incorporation) {
                currentApplication.date_of_incorporation = formatDate(
                    currentApplication.date_of_incorporation,
                    'yyyy-MM-dd'
                );
            }

            if (currentApplication.date_of_birth) {
                currentApplication.date_of_birth = formatDate(
                    currentApplication.date_of_birth,
                    'yyyy-MM-dd'
                );
            }

            if (currentApplication.status !== 0) {
                onComplete(currentApplication.status);
            }

            setCompany(companyInfo);
            setApplication(currentApplication);
            setDataLoaded(true);
            await sendApplicationParams(searchParams);
        })();
    }, []);

    const sendApplicationParams = async query => {
        const meta = {};
        const fingerprint = await getFingerprint();
        meta.fingerprint = fingerprint;
        if (!!query.size) {
            for (const key of query.keys()) {
                meta[key] = query.get(key);
            }
        }
        await api.postApplication({ meta });
    };

    const handleNext = () => {
        setActiveStep(prevActiveStep => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep(prevActiveStep => prevActiveStep - 1);
    };

    const handleApplicationChange = values => {
        setApplication(currentValue => ({
            ...currentValue,
            ...values,
        }));
    };

    const getStep = key => {
        if (key === 'personal') {
            return (
                <PersonalInfoForm
                    application={application}
                    handleApplicationChange={handleApplicationChange}
                    handleNext={handleNext}
                />
            );
        } else if (key === 'business') {
            return (
                <BusinessInfoForm
                    application={application}
                    handleApplicationChange={handleApplicationChange}
                    handleNext={handleNext}
                    handleBack={handleBack}
                />
            );
        } else if (key === 'additional') {
            return (
                <AdditionalInfoForm
                    application={application}
                    handleApplicationChange={handleApplicationChange}
                    handleNext={handleNext}
                    handleBack={handleBack}
                    companyId={company.company.id}
                />
            );
        }
    };

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                minWidth: 350,
                width: '100%',
                maxWidth: 600,
                paddingX: 2,
                pt: 1,
                pb: 6,
                boxSizing: 'border-box',
            }}
        >
            <Logo height={20} />
            <BreadCrumbs page={1} company_id={company.id} />
            <MarketingCard activeStep={activeStep} page={1} />
            {dataLoaded ? (
                <Stepper activeStep={activeStep} orientation="vertical">
                    {steps.map((step, index) => (
                        <Step
                            key={step.key}
                            sx={{
                                '& .MuiStepLabel-root .Mui-completed': {
                                    color: 'success.main', // circle color (COMPLETED)
                                },
                                '& .MuiStepLabel-label.Mui-completed': {
                                    color: 'text.secondary', // Just text label (COMPLETED)
                                },
                                '& .MuiStepLabel-root .Mui-active': {
                                    color: 'text.secondary', // circle color (ACTIVE)
                                },
                            }}
                        >
                            <StepLabel>{step.label}</StepLabel>
                            <StepContent>{getStep(step.key)}</StepContent>
                        </Step>
                    ))}
                </Stepper>
            ) : (
                <Box
                    sx={{
                        height: '100%',
                        minHeight: 500,
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <CircularProgress />
                </Box>
            )}
        </Box>
    );
};
