import { useEffect, useState, useCallback } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box, Button, Typography, Card } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import {
    usePlaidLink,
    PlaidLinkOnSuccess,
    PlaidLinkOnSuccessMetadata,
    PlaidLinkOnEvent,
    PlaidLinkOnExit,
    PlaidLinkOnEventMetadata,
} from 'react-plaid-link';
import { PlaidLogo } from '@lendica/components';
import AddIcon from '@mui/icons-material/Add';
import * as api from '../api/index';
import { DropzoneFile } from '@lendica/components';
import { UploadList } from './UploadList';
import { UnableToConnect } from './UnableToConnect';
import { postCustomerSupport } from '@lendica/api';

export const Plaid = ({
    company_id,
    handleConnection,
    env,
}: {
    company_id: string;
    handleConnection: any;
    env: string;
}) => {
    const [expanded, setExpanded] = useState(false);
    const [token, setToken] = useState(null);
    const [uploadedFiles, setUploadedFiles] = useState<DropzoneFile[]>([]);
    const [connectedAccounts, setConnectedAccounts] = useState<PlaidLinkOnSuccessMetadata[]>([]);
    const [exited, setExited] = useState(false);

    let baseURL =
        env === 'idev'
            ? 'https://micro-oauth-dev.herokuapp.com'
            : env === 'sandbox'
            ? 'https://micro-oauth-sandbox.herokuapp.com/'
            : 'https://oauth.app.golendica.com';

    // get a link_token from your API when component mounts
    useEffect(() => {
        const createLinkToken = async () => {
            const token = await api.getPlaidToken(baseURL);
            setToken(token);
        };
        createLinkToken();
    }, []);

    const onSuccess = useCallback<PlaidLinkOnSuccess>(
        async (publicToken: string, metadata: PlaidLinkOnSuccessMetadata) => {
            // send public_token to your server
            // https://plaid.com/docs/api/tokens/#token-exchange-flow
            try {
                const response = await api.postPlaidToken(publicToken, company_id, baseURL);

                setConnectedAccounts(prev => [...prev, metadata]);
            } catch (error) {
                // TODO: handle plaid post link token error
                console.log(error);
            } finally {
                setExpanded(false);
                // handleConnection();
            }
        },
        []
    );

    useEffect(() => {
        if (connectedAccounts.length > 0) {
            handleConnection();
            const flatConnections = connectedAccounts.flatMap(connection =>
                connection?.accounts?.map(account => ({
                    institution_name: connection?.institution?.name,
                    institution_id: connection?.institution?.institution_id,
                    ...account,
                }))
            );
            localStorage.setItem('ica_plaid', JSON.stringify(flatConnections));
            const blocks = [
                {
                    type: 'header',
                    text: {
                        type: 'plain_text',
                        text: 'New Bank Connection',
                        emoji: true,
                    },
                },
                {
                    type: 'section',
                    text: {
                        type: 'mrkdwn',
                        text: `Company ID: ${company_id}, \n Environment: ${env}, \n Connected Accounts: \n\`\`\`${JSON.stringify(
                            flatConnections
                        )}\`\`\`\n`,
                    },
                },
            ];
            postCustomerSupport(blocks);
        }
    }, [connectedAccounts]);

    // const onEvent = useCallback<PlaidLinkOnEvent>((eventName, metadata) => {
    //     // log onEvent callbacks from Link
    //     // https://plaid.com/docs/link/web/#onevent
    //     // console.log(metadata)
    //     // console.log(eventName)
    //     /* Per https://plaid.com/docs/link/web/, "eventName" is "a string representing the event that has just occurred
    //        in the Link flow," and the HANDOFF value indicates that "the user has exited Link after successfully linking an Item." */
    //     if (eventName === 'HANDOFF') {
    //         setConnectedAccounts(prev => [...prev, metadata]);
    //         handleConnection();
    //     }
    // }, []);

    const onExit = useCallback<PlaidLinkOnExit>((error, metadata) => {
        // log onExit callbacks from Link, handle errors
        // https://plaid.com/docs/link/web/#onexit
        // console.log(error, metadata);
        if (connectedAccounts.length === 0) {
            setExited(true);
        }
    }, []);

    const config = {
        token,
        onSuccess,
        // onEvent,
        onExit,
    };

    const {
        open,
        ready,
        // error,
        // exit
    } = usePlaidLink(config);

    const handleFileUpload = (file: any) => {
        handleConnection();
        setUploadedFiles(file);
    };

    return (
        <Accordion
            expanded={expanded}
            onChange={() => setExpanded(!expanded)}
            sx={{
                '&:before': {
                    display: 'none',
                },
                'borderRadius': 1,
                'boxShadow': 'none',
                'paddingTop': 1,
                'background': '##EDEDF4',
                'border': '1px solid #EDEDF4',
                'paddingBottom': expanded ? 0 : 1,
                'marginTop': 1,
            }}
            data-testid="banking-accordion"
        >
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                color="#FFF"
                sx={{
                    border: 'none',
                    boxShadow: 'none',
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                    }}
                >
                    <AccountBalanceIcon />
                    <Typography fontWeight={500} ml={2}>
                        Bank
                    </Typography>
                    <Typography variant="caption" fontWeight={400} ml={2}>
                        required
                    </Typography>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        paddingX: '1rem',
                        alignItems: 'center',
                        width: '100%',
                    }}
                >
                    {(connectedAccounts.length > 0 || uploadedFiles.length > 0) && (
                        <CheckCircleIcon color="success" />
                    )}
                </Box>
            </AccordionSummary>
            <AccordionDetails sx={{ padding: 0 }}>
                <Box>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            padding: '1rem 1rem',
                            background: '#f2f2f8',
                            borderRadius: 1,
                            marginY: 1,
                            marginX: 2,
                            cursor: 'pointer',
                        }}
                        component="div"
                        onClick={() => open()}
                        data-testid="open-plaid-popup"
                    >
                        <PlaidLogo />
                        <AddIcon />
                    </Box>

                    {exited && (
                        <Box
                            sx={{
                                marginX: 2,
                                marginBottom: 1,
                            }}
                        >
                            <UnableToConnect
                                company_id={company_id}
                                caption={"I'm not able to connect to Plaid."}
                                secondaryCaption={
                                    'Please upload at least one invoice/bank statement'
                                }
                                uploadedFiles={uploadedFiles}
                                fileUploadHandler={handleFileUpload}
                                type="bank"
                                env={env}
                            />
                        </Box>
                    )}

                    <Box
                        sx={{
                            height: 'fit-content',
                            display: 'flex',
                            flexDirection: 'column',
                            background: 'background.card',
                        }}
                    >
                        <UploadList
                            connectedAccounts={connectedAccounts}
                            uploadedFiles={uploadedFiles}
                        />
                    </Box>
                </Box>
            </AccordionDetails>
        </Accordion>
    );
};
