import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ModeStandbyIcon from '@mui/icons-material/ModeStandby';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';

export const UploadList = ({ connectedAccounts, uploadedFiles }) => {
    // TODO: is this implemented for plaid only? (account.insitution_name)
    // https://plaid.com/docs/link/web/#link-web-onsuccess-metadata
    // Need to refactor to handle other data connections, unify the schema for all connections.
    let numConnectedAccounts = connectedAccounts.length;
    let numUploadedFiles = uploadedFiles.length;

    if (numConnectedAccounts !== 0 || uploadedFiles.length !== 0) {
        return (
            <Box
                sx={{
                    width: '100%',
                    height: 'fit-content',
                    background: '#f2f2f8',
                    mt: 1,
                    paddingX: 2,
                    boxSizing: 'border-box',
                }}
            >
                <List>
                    {connectedAccounts.map((connection, i) =>
                        connection.accounts.map((account, j) => {
                            if (j > 0) {
                                numConnectedAccounts += 1;
                            }
                            return (
                                <ListItem
                                    key={account?.id}
                                    divider={false}
                                    // divider={j === numConnectedAccounts - 1 ? false : true}
                                    disable
                                >
                                    <ListItemIcon>
                                        <ModeStandbyIcon color="success" />
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={`${account?.name} - ${account?.mask}`}
                                        secondary={connection?.institution?.name}
                                    />
                                </ListItem>
                            );
                        })
                    )}
                    {uploadedFiles.map((file, i) => (
                        <ListItem divider={i === numUploadedFiles - 1 ? false : true} disable>
                            <ListItemIcon>
                                <PictureAsPdfIcon />
                            </ListItemIcon>
                            <ListItemText primary={file.path} />
                        </ListItem>
                    ))}
                </List>
            </Box>
        );
    } else {
        return null;
    }
};
