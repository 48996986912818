import { Route, Routes } from 'react-router-dom';
import { ApplicationPage } from './pages/ApplicationPage';
import { ApplicationSubmittedPage } from './pages/ApplicationSubmittedPage';
import { DataConnectionPage } from './pages/DataConnectionPage';
import { GetStartedPage } from './pages/GetStartedPage';
import { ShippingInfoPage } from './pages/ShippingInfoPage';
import { ShippingOptionsPage } from './pages/ShippingOptionsPage';
import { ConsentPage } from './pages/ConsentPage';
import { ConsentProtector } from './pages/ConsentProtector';
import { ApplicationProvider } from './context/useApplication';

export const ApplicationForm = ({ onComplete, env }) => {
    return (
        <ApplicationProvider>
            <Routes path="*">
                <Route path="consent" element={<ConsentPage />} />
                <Route
                    path="application"
                    element={
                        <ConsentProtector>
                            <ApplicationPage onComplete={onComplete} />
                        </ConsentProtector>
                    }
                />
                <Route path="get-started" element={<GetStartedPage env={env} />} />
                <Route path="get-started/:company_id" element={<GetStartedPage env={env} />} />
                <Route path="submitted" element={<ApplicationSubmittedPage env={env} />} />
                <Route
                    path="submitted/:company_id"
                    element={<ApplicationSubmittedPage env={env} />}
                />
                <Route path="shipping-info" element={<ShippingInfoPage />} />
                <Route path="shipping-info/:bill_id" element={<ShippingInfoPage />} />
                <Route path="shipping-methods/:bill_id" element={<ShippingOptionsPage />} />
                <Route
                    path="data-connection/:company_id"
                    element={<DataConnectionPage onComplete={onComplete} env={env} />}
                />
            </Routes>
        </ApplicationProvider>
    );
};
