import { alpha } from '@mui/material';

// import InterTtf from './assets/Inter.ttf';
// import PoiretOneTtf from './assets/PoiretOne.ttf';

// const inter = {
//     fontFamily: 'Inter',
//     fontStyle: 'normal',
//     fontDisplay: 'swap',
//     fontWeight: 300,
//     src: `local('Inter'), local('Inter-Regular'), url(${InterTtf}) format('ttf')`,
//     unicodeRange:
//         'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
// };

// const poiretOne = {
//     fontFamily: 'Poiret One',
//     fontStyle: 'normal',
//     fontDisplay: 'swap',
//     fontWeight: 400,
//     src: `local('Poiret One'), local('PoiretOne-Regular'), url(${PoiretOneTtf}) format('ttf')`,
//     unicodeRange:
//         'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
// };

export const theme = {
    palette: {
        primary: { main: '#2E3192', light: '#8283BE', dark: '#282761', contrast: '#FCFCFD' },
        secondary: { main: '#0E0E2C', light: '#6E6E80', dark: '#3E3E56' },
        success: { main: '#58A10E', light: '#9BC76E', dark: '#46800B' },
        error: { main: '#ED7A56', light: '#F4AF9A', dark: '#DD4718' },
        warning: { main: '#FAAF6D', light: '#FCCEA6', dark: '#F98F34' },
        action: { disabled: '#BABAC7', active: '#8C8CA1' },
        info: {
            main: '#4A4A68',
            dark: '#3B3B53',
            light: '#9292A4',
        },
        text: {
            primary: '#0E0E2C',
            secondary: '#4A4A68',
            disabled: '#8C8CA1',
        },
        background: {
            paper: '#FCFCFD',
            default: '#F8F8FB',
            card: '#F2F2F8',
            cardLight: '#F8F8FB',
            cardBorder: '#EDEDF4',
        },
    },
    typography: {
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            'Roboto',
            '"Segoe UI"',
            '"Helvetica Neue"',
            'sans-serif',
        ].join(','),
        fontWeightRegular: 300,
        fontWeightSemibold: 600,
        button: {
            fontWeight: 400,
            textTransform: 'none',
        },
        subtitle1: {
            fontWeight: 600,
        },
        subtitle2: {
            fontWeight: 300,
        },
        h4: {
            fontWeight: 700,
        },
        h5: {
            fontWeight: 200,
        },
        caption: {
            color: '#4A4A68',
            fontWeight: 400,
        },
        overline: {
            color: '#8C8CA1',
            fontSize: '0.6875rem',
        },
    },
    components: {
        MuiButton: {
            variants: [
                {
                    props: { variant: 'text' },
                    style: {
                        'borderRadius': 100,
                        'boxShadow': 'none',
                        ':hover': {
                            boxShadow: 'none',
                        },
                    },
                },
                {
                    props: { variant: 'contained' },
                    style: {
                        'borderRadius': 100,
                        'boxShadow': 'none',
                        ':hover': {
                            boxShadow: 'none',
                            opacity: 0.85,
                        },
                    },
                },
                {
                    props: { variant: 'outlined' },
                    style: {
                        'borderRadius': 100,
                        'boxShadow': 'none',
                        ':hover': {
                            boxShadow: 'none',
                        },
                    },
                },
            ],
        },
        MuiBottomNavigationAction: {
            styleOverrides: {
                root: {
                    '&.Mui-selected': {
                        color: '#0E0E2C',
                    },
                },
            },
        },
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 900,
            lg: 1200,
            xl: 1536,
        },
    },
};

// export const theme = {
//     breakpoints: {
//         values: {
//             xs: 0,
//             sm: 600,
//             md: 900,
//             lg: 1200,
//             xl: 1536,
//         },
//     },
//     typography: {
//         fontFamily: [
//             '-apple-system',
//             'BlinkMacSystemFont',
//             // 'Inter',
//             '"Segoe UI"',
//             '"Helvetica Neue"',
//             'sans-serif',
//         ].join(','),
//         fontWeightRegular: 300,
//         fontWeightSemibold: 600,
//         button: { fontWeight: 400 },
//         subtitle1: {
//             fontWeight: 600,
//         },
//         subtitle2: {
//             fontWeight: 300,
//         },
//         h4: {
//             fontWeight: 700,
//         },
//         h5: {
//             fontWeight: 200,
//         },
//         caption: {
//             color: '#8C8CA1',
//         },
//         overline: {
//             color: '#8C8CA1',
//             fontSize: '0.6875rem',
//         },
//     },
//     components: {
//         // MuiCssBaseline: {
//         //     '@global': {
//         //         '@font-face': [inter, poiretOne],
//         //     },
//         // },
//         MuiButton: {
//             variants: [
//                 {
//                     props: { variant: 'contained' },
//                     style: {
//                         borderRadius: 100,
//                     },
//                 },
//                 {
//                     props: { variant: 'outlined' },
//                     style: {
//                         borderRadius: 100,
//                     },
//                 },
//                 {
//                     props: { variant: 'containedSecondary' },
//                     style: {
//                         '&:hover': {
//                             backgroundColor: alpha('#2E3192', 0.8),
//                         },
//                     },
//                 },
//             ],
//         },
//     },
//     palette: {
//         primary: { main: '#0E0F2C' },
//         secondary: { main: '#2E3192', light: '#EDEDF4' },
//         success: { main: '#58A10E' },
//         error: { main: '#ED7A56' },
//         warning: { main: '#FAAF6D' },
//         action: { disabled: '#8C8CA1' },
//         info: {
//             main: '#4A4A68',
//             dark: '#3B3B53',
//             light: '#9292A4',
//         },
//         neutral: {
//             main: '#E0E0E0',
//         },
//         text: {
//             primary: '#0E0E2C',
//             secondary: '#4A4A68',
//             disabled: '#8C8CA1',
//         },
//         background: {
//             paper: '#FCFCFD',
//             default: '#F8F8FB',
//             card: '#F2F2F8',
//             cardLight: '#F8F8FB',
//             cardBorder: '#EDEDF4',
//             green: '#4CAF50',
//         },
//     },
// };

// export const theme = createTheme({
//     palette: {
//         primary: { main: '#2E3192', light: '#8283BE', dark: '#282761' },
//         secondary: { main: '#0E0E2C', light: '#6E6E80', dark: '#3E3E56' },
//         success: { main: '#58A10E', light: '#9BC76E', dark: '#46800B' },
//         error: { main: '#ED7A56', light: '#F4AF9A', dark: '#DD4718' },
//         warning: { main: '#FAAF6D', light: '#FCCEA6', dark: '#F98F34' },
//         action: { disabled: '#BABAC7', active: '#8C8CA1' },
//         info: {
//             main: '#4A4A68',
//             dark: '#3B3B53',
//             light: '#9292A4',
//         },
//         text: {
//             primary: '#0E0E2C',
//             secondary: '#4A4A68',
//             disabled: '#8C8CA1',
//         },
//         background: {
//             paper: '#FCFCFD',
//             default: '#F8F8FB',
//         },
//     },
//     typography: {
//         fontFamily: [
//             '-apple-system',
//             'BlinkMacSystemFont',
//             'Roboto',
//             '"Segoe UI"',
//             '"Helvetica Neue"',
//             'sans-serif',
//         ].join(','),
//         fontWeightRegular: 300,
//         button: { fontWeight: 400 },
//         subtitle1: {
//             fontWeight: 600,
//         },
//         subtitle2: {
//             fontWeight: 300,
//         },
//         h4: {
//             fontWeight: 700,
//         },
//         h5: {
//             fontWeight: 100,
//         },
//         caption: {
//             color: '#8C8CA1',
//         },
//         overline: {
//             color: '#8C8CA1',
//             fontSize: '0.6875rem',
//         },
//     },
//     components: {
//         MuiButton: {
//             variants: [
//                 {
//                     props: { variant: 'text' },
//                     style: {
//                         borderRadius: 100,
//                         boxShadow: "none",
//                         ":hover": {
//                             boxShadow: "none",
//                         }
//                     },
//                 },
//                 {
//                     props: { variant: 'contained' },
//                     style: {
//                         borderRadius: 100,
//                         boxShadow: "none",
//                         ":hover": {
//                             boxShadow: "none",
//                             opacity: 0.85,
//                         }
//                     },
//                 },
//                 {
//                     props: { variant: 'outlined' },
//                     style: {
//                         borderRadius: 100,
//                         boxShadow: "none",
//                         ":hover": {
//                             boxShadow: "none"
//                         }
//                     },
//                 },
//             ],
//         },
//     },
// });
