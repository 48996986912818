import { useMemo, useEffect, useRef, useState } from 'react';
import { Box, Grid, TextField, Typography, Popper } from '@mui/material';
import { makeStyles } from '@mui/styles';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { Autocomplete } from '@mui/material';
import parse from 'autosuggest-highlight/parse';
import throttle from 'lodash/throttle';

const apiKey = 'AIzaSyDpaTbeSIERqaAMp2QhGK--Bpx2tmufWWU';

const loadScript = (position, id) => {
    if (!position) {
        return;
    }

    const script = document.createElement('script');
    script.setAttribute('async', '');
    script.setAttribute('id', id);
    script.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places`;
    position.appendChild(script);
};

const autocompleteService = { current: null };

const useStyles = makeStyles(theme => ({
    icon: {
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(2),
    },
    popper: {
        transform: 'translate(0, 40px) !important',
    }
}));

const TopPopper = ({ placement, ...props }) => <Popper {...props} placement="bottom" disablePortal />;

export const PlaceAutocomplete = ({ className, onChange, value, ...props }) => {
    const classes = useStyles();
    const [inputValue, setInputValue] = useState('');
    const [options, setOptions] = useState([]);
    const loaded = useRef(false);

    useEffect(() => {
        if (typeof window !== 'undefined' && !loaded.current) {
            if (!document.querySelector('#google-maps')) {
                loadScript(document.querySelector('head'), 'google-maps');
            }

            loaded.current = true;
        }
    }, []);

    const fetch = useMemo(
        () =>
            throttle((request, callback) => {
                autocompleteService.current.getPlacePredictions(request, callback);
            }, 200),
        []
    );

    useEffect(() => {
        let active = true;

        if (!autocompleteService.current && window.google) {
            autocompleteService.current = new window.google.maps.places.AutocompleteService();
        }
        if (!autocompleteService.current) {
            return undefined;
        }

        if (inputValue === '') {
            setOptions([]);
            return undefined;
        }

        fetch({ input: inputValue, componentRestrictions: { country: 'us' } }, results => {
            if (active) {
                let newOptions = [];

                if (results) {
                    newOptions = [...newOptions, ...results];
                }
                setOptions(newOptions);
            }
        });

        return () => {
            active = false;
        };
    }, [inputValue, fetch]);


    const getRenderOption = (props, option) => {
        const matches = option.structured_formatting.main_text_matched_substrings;
        const parts = parse(
            option.structured_formatting.main_text,
            matches.map(match => [match.offset, match.offset + match.length])
        );

        return (
            <li {...props}>
                <Grid container alignItems="center">
                    <Grid item>
                        <LocationOnIcon className={classes.icon} />
                    </Grid>
                    <Grid item xs>
                        {parts.map((part, index) => (
                            <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                                {part.text}
                            </span>
                        ))}

                        <Typography variant="body2" color="text.secondary">
                            {option.structured_formatting.secondary_text}
                        </Typography>
                    </Grid>
                </Grid>
            </li>
        );
    };

    const getOptionLabel = option => (typeof option === 'string' ? option : option.description);

    const isOptionEqualToValue = (option, value) => option.description === value;

    const handleChange = (_, data) => {
        onChange(data);
    };

    const handleInputChange = (_, newInputValue) => {
        setInputValue(newInputValue);
    };

    return (
        <Box sx={{ position: 'relative', width: '100%' }}>
            <Autocomplete
                className={className}
                options={options}
                value={value}
                getOptionLabel={getOptionLabel}
                isOptionEqualToValue={isOptionEqualToValue}
                filterSelectedOptions
                filterOptions={x => x}
                renderOption={getRenderOption}
                renderInput={params => <TextField {...params} {...props} color="primary" />}
                onChange={handleChange}
                onInputChange={handleInputChange}
                PopperComponent={TopPopper}
                size="small"
                sx={{ width: '100%' }}
                classes={{ popper: classes.popper }}
            />
        </Box>
    );
};
