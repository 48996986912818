import { Box, TextField } from "@mui/material"
import { useFormik } from 'formik';
import * as yup from 'yup';
import { makeStyles } from "@mui/styles";
import { RoundedButton } from "@lendica/components";
import * as api from '../api/index'
import NumberFormat from 'react-number-format';

const useStyles = makeStyles(theme => ({
    'row': {
        display: 'flex',
        gap: '1rem',
        flexDirection: 'row',

        [theme.breakpoints.down('md')]: {
            flexDirection: 'column'
        }
    }
}))

const validationSchema = yup.object({
    first_name: yup
        .string('Enter your first name')
        .required('First name is required'),
    last_name: yup
        .string('Enter your last name')
        .required('Last name is required'),
    email: yup
        .string('Enter your email')
        .email('Enter a valid email')
        .required('Email is required'),
    phone_number: yup
        .string()
        .test('len', 'Please provide a valid phone number', val => val?.length === 10)
        .required('Phone is required'),
});

export const PersonalInfoForm = ({ application, handleApplicationChange, handleNext }) => {

    const formik = useFormik({
        initialValues: {
            first_name: application.first_name || '',
            last_name: application.last_name || '',
            email: application.email || '',
            phone_number: application.phone_number || ''
        },
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            await api.postApplication(values)
            handleApplicationChange(values)
            handleNext()
        },
    });


    const classes = useStyles();

    return (
        <form onSubmit={formik.handleSubmit} style={{ margin: '1.5rem 0' }}>
            <Box className={classes.row} my={2}>
                <TextField
                    label="First Name"
                    name="first_name"
                    id="first_name"
                    value={formik.values.first_name}
                    onChange={formik.handleChange}
                    error={formik.touched.first_name && Boolean(formik.errors.first_name)}
                    helperText={formik.touched.first_name && formik.errors.first_name}
                    size="small"
                    fullWidth
                    sx={{ flex: 1 }}
                    color='primary'
                    inputProps={{ 'data-testid': 'first_name' }}
                />
                <TextField
                    label="Last Name"
                    name="last_name"
                    id="last_name"
                    value={formik.values.last_name}
                    onChange={formik.handleChange}
                    error={formik.touched.last_name && Boolean(formik.errors.last_name)}
                    helperText={formik.touched.last_name && formik.errors.last_name}
                    size="small"
                    fullWidth
                    sx={{ flex: 1 }}
                    color='primary'
                    inputProps={{ 'data-testid': 'last_name' }}
                />
            </Box>

            <Box sx={{ display: 'flex' }} mb={2}>
                <TextField
                    label="Email"
                    name="email"
                    id="email"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    error={formik.touched.email && Boolean(formik.errors.email)}
                    helperText={formik.touched.email && formik.errors.email}
                    size="small"
                    fullWidth
                    color='primary'
                    inputProps={{ 'data-testid': 'email' }}
                />
            </Box>

            <Box sx={{ display: 'flex' }} mb={2}>
                <NumberFormat
                    mask="_"
                    customInput={TextField}
                    format="+1 (###) ###-####"
                    label="Phone number"
                    name="phone_number"
                    id="phone_number"
                    value={formik.values.phone_number}
                    onValueChange={({ value }) => {
                        formik.setFieldValue('phone_number', value);
                    }}
                    isNumericString
                    error={formik.touched.phone_number && Boolean(formik.errors.phone_number)}
                    helperText={formik.touched.phone_number && formik.errors.phone_number}
                    size="small"
                    fullWidth
                    sx={{ flex: 1 }}
                    color="primary"
                />
            </Box>

            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                <RoundedButton
                    sx={{
                        flex: 1,
                        mt: 1,
                        mr: 1,
                        borderColor: 'primary.light',
                        border: '1px solid',
                        color: 'primary.main'
                    }}
                    disabled
                >
                    Back
                </RoundedButton>
                <RoundedButton
                    sx={{ flex: 1, mt: 1 }}
                    variant="contained"
                    color="primary"
                    type="submit"
                    data-testid="personal-info-form-submit-button"
                >
                    Continue
                </RoundedButton>
            </Box>
        </form>
    )
}